import React, { useState } from 'react';
import './app.css';
import Web3 from 'web3';
import * as bitcoin from 'bitcoinjs-lib';

function App() {

  const [matchingCharacters, setMatchingCharacters] = useState('');
  const [isLeading, setIsLeading] = useState( false );
  const [showOutput, setShowOutput] = useState( false );
  const [address, setAddress] = useState( '');
  const [privateKey, setPrivateKey] = useState( '');
  const [blockchain, setBlockchain] = useState( 'bitcoin' );

  function startMining(e) {
    if ( matchingCharacters.length === 0 ) {
      alert( 'please input characters');
      return;
    }

    let lowercase = matchingCharacters.toLowerCase();
    let validChars = '';
    if ( blockchain === 'bitcoin' ) {
      validChars = '123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ';
    } else if ( blockchain === 'ethereum' ) {
      validChars = '0123456789abcdef';
    }

    for (const c of lowercase) {
      if ( !validChars.includes( c.toString() ) ) {
        alert( `valid chars are ${validChars}` )
        return;
      }
    }

    e.preventDefault();

    if ( blockchain === 'bitcoin' ) {
      mineBitcoinAddress();
    } else if ( blockchain === 'ethereum' ) {
      mineEthereumAddress();
    }

  }

  function mineEthereumAddress() {
    let lowercase = matchingCharacters.toLowerCase();
    const web3 = new Web3();
    let payload = null;
    while (payload == null) {
      const data = web3.eth.accounts.create(web3.utils.randomHex(32));
      if ( isLeading ) {
        if (data.address.toLowerCase().startsWith(lowercase, 2)) {
          payload = data;
        }
      } else {
        if (data.address.toLowerCase().includes(lowercase)) {
          payload = data;
        }
      }
    }

    foundAddress( payload );
  }

  function mineBitcoinAddress() {
    let lowercase = matchingCharacters.toLowerCase();
    let payload = null;
    while (payload == null) {
      const keyPair = bitcoin.ECPair.makeRandom();
      const { address: aAddress } = bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey })

      if ( isLeading ) {
        if (aAddress.toLowerCase().startsWith(lowercase, 1)) {
          payload = {address: aAddress, privateKey: keyPair.toWIF() };
        }
      } else {
        if (aAddress.toLowerCase().includes(lowercase)) {
          payload = {address: aAddress, privateKey: keyPair.toWIF() };
        }
      }
    }

    foundAddress( payload );
  }

  function foundAddress( payload ) {
    setAddress( payload.address );
    setPrivateKey( payload.privateKey );
    setShowOutput( true );
    console.log( `Mined! Address: ${payload.address} \nPrivate key is: ${payload.privateKey }` );
  }

  return (
      <div className="App">
        <header className="App-header">
          <p>
            <code>Vanity Address Miner</code>
          </p>
          <p className="App-description"><code>generates keys locally until one matches your preferences</code></p>
          <div>
            <form onSubmit={e => startMining(e)}>
              <p className="Options">
                <p className="Option RadioButton">
                  <label className="Option">
                    <code>Bitcoin</code>
                    <p>
                      <input type="radio" id="BTC" name="blockchain" value="bitcoin" onChange={e => setBlockchain(e.target.value)} defaultChecked />
                    </p>
                  </label>
                  <label className="Option">
                    <code>Ethereum</code>
                    <p>
                      <input type="radio" id="BTC" name="blockchain" value="ethereum" onChange={e => setBlockchain(e.target.value)} />
                    </p>
                  </label>
                </p>
                <label className="Option">

                  <code>Characters in address:</code>
                  <p>
                    <input type="text" name="matchingChars" onChange={e => setMatchingCharacters(e.target.value)}/>
                  </p>
                </label>

                <label className="Option">
                  <code>Are characters Leading?</code>
                  <p>
                    <input type="checkbox" name="isLeading" onChange={e => setIsLeading( e.target.checked )}/>
                  </p>
                </label>
              </p>
              <p>
                <input type="submit" value="Start mining!" />
              </p>
            </form>
          </div>
          { showOutput &&
          <div>
            <p className="Option">Address:</p>
            <p>{address}</p>
            <p className="Option">Private Key:</p>
            <p>{privateKey}</p>
          </div>
          }
        </header>
      </div>
  );
}

export default App;
